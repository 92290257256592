import { Checkbox, Image, Space, Tag, Popconfirm, Typography } from 'antd';

export const columns = ({ isEditing, save, cancel, editingKey, edit }) => [
	// {
	// 	title: 'Video Id',
	// 	dataIndex: 'videoId',
	// 	key: 'videoId',
	// },
	{
		title: 'Title',
		dataIndex: 'originalTitle',
		key: 'originalTitle',
		editable: true,
	},
	{
		title: 'Image',
		dataIndex: 'thumbnails',
		key: 'image',
		render: (e) => <Image src={e.default.url} width={'80px'} height={'45px'} />,
	},
	{
		title: 'isProcessed',
		dataIndex: 'isProcessed',
		key: 'isProcessed',
		render: (e) => <Checkbox checked={e} disabled />,
	},
	{
		title: 'hasError',
		dataIndex: 'hasError',
		key: 'hasError',
		render: (e) => <Checkbox checked={e} disabled />,
	},
	{
		title: 'channelTitle',
		dataIndex: 'channelTitle',
		key: 'channelTitle',
	},
	{
		title: 'defaultAudioLanguage',
		dataIndex: 'defaultAudioLanguage',
		key: 'defaultAudioLanguage',
		editable: true,
	},
	// {
	// 	title: 'madeForKids',
	// 	dataIndex: 'madeForKids',
	// 	key: 'madeForKids',
	// 	render: (e) => <Checkbox checked={e} disabled />,
	// },
	// {
	// 	title: 'privacyStatus',
	// 	dataIndex: 'privacyStatus',
	// 	key: 'privacyStatus',
	// },
	// {
	// 	title: 'allowed',
	// 	key: 'allowed',
	// 	dataIndex: 'allowed',
	// 	render: (tags) => (
	// 		<>
	// 			{tags?.map((tag) => {
	// 				let color = tag.length > 5 ? 'geekblue' : 'green';
	// 				if (tag === 'loser') {
	// 					color = 'volcano';
	// 				}
	// 				return (
	// 					<Tag color={color} key={tag} style={{ paddingLeft: '2px', paddingRight: '2px', margin: '1px' }}>
	// 						{tag.toUpperCase()}
	// 					</Tag>
	// 				);
	// 			})}
	// 		</>
	// 	),
	// },
	// {
	// 	title: 'blocked',
	// 	key: 'blocked',
	// 	dataIndex: 'blocked',
	// 	render: (tags) => (
	// 		<>
	// 			{tags?.map((tag) => {
	// 				let color = tags.length > 5 ? 'geekblue' : 'green';
	// 				if (tag === 'loser') {
	// 					color = 'volcano';
	// 				}
	// 				return (
	// 					<Space.Compact>
	// 						<Tag color={color} key={tag} style={{ paddingLeft: '2px', paddingRight: '2px', margin: '1px' }}>
	// 							{tag.toUpperCase()}
	// 						</Tag>
	// 					</Space.Compact>
	// 				);
	// 			})}
	// 		</>
	// 	),
	// },
	{
		title: 'operation',
		dataIndex: 'operation',
		render: (_, record) => {
			const editable = isEditing(record);
			return editable ? (
				<span>
					<Typography.Link
						onClick={() => save(record.videoId)}
						style={{
							marginRight: 8,
						}}
					>
						Save
					</Typography.Link>
					<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
						<Typography.Link> Cancel</Typography.Link>
					</Popconfirm>
				</span>
			) : (
				<>
					<Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
						Edit
					</Typography.Link>
					{/* <Typography.Link disabled={editingKey !== ''} onClick={() => deleteRecord(record)}>
						Delete
					</Typography.Link> */}
				</>
			);
		},
	},
];
