import VideoCard from './Card';

const ListCards = (props) => {
	const { data } = props;

	return (
		<>
			{Object.values(data).map((video) => {
				if (
					(!!video.allowed && video.allowed.includes('IN') && !video.blocked.length) ||
					(!!video.blocked && !video.blocked.includes('IN') && !video.allowed.length) ||
					(!video.blocked.length && !video.allowed.length)
				) {
					return <VideoCard key={video.videoId} video={video} />;
				} else {
					return null;
				}
			})}
		</>
	);
};

export default ListCards;
