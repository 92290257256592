const initialState = {
	settings: {
		userRegion: null,
		userLanguage: 'en',
		videosPerPage: 50,
	},
	videos: {},
	filterCondition: {},
	isLoggedIn: false,
};
export { initialState };
