import { Button, Layout, Space } from 'antd';
import AddMovieModal from './modal/AddMovieModal';
import { useState } from 'react';
import { BASE_URL } from '../utils/constants';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppState } from '../context';
const { Header } = Layout;

const MainHeader = () => {
	const { state } = useAppState();

	const isLoggedIn = state?.isLoggedIn;

	const [showModal, setShowModal] = useState(false);

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const onCancelHandler = () => setShowModal(!showModal);
	const onOkHandler = async (videoId) => {
		try {
			await axios.post(`${BASE_URL}/addMovie`, {
				ytVideoId: videoId,
			});
			setShowModal(false);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			<Header style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<div className="logo" onClick={() => navigate('/')}>
					<span style={{ color: '#F89F1D' }}> GEN</span>
					<span style={{ color: '#fdfdfd' }}>BAYS</span>
				</div>

				<Space.Compact className="header-button-space">
					{isLoggedIn &&
						(pathname === '/movielist' ? (
							<Button onClick={() => navigate('/browse')}>Browse </Button>
						) : (
							<Button onClick={() => navigate('/movielist')}>Movie List</Button>
						))}
					<Button onClick={() => setShowModal(true)}>Add movie</Button>
				</Space.Compact>
			</Header>
			<AddMovieModal showModal={showModal} onCancelHandler={onCancelHandler} onOkHandler={onOkHandler} />
		</>
	);
};

export default MainHeader;
