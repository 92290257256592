import { Button, Card, Col, Space } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { BASE_URL } from '../utils/constants';
import { useAppState } from '../context';

const { Meta } = Card;

const VideoCard = ({ video }) => {
	const { state, dispatch } = useAppState();

	function n(n) {
		return n > 9 ? '' + n : '0' + n;
	}
	const convertPT = (playTime) => {
		let h = 0,
			m = 0,
			s = 0;
		let pt = playTime.split('PT')[1];
		if (pt) [h, pt] = pt.split('H');
		if (pt) [m, pt] = pt.split('M');
		if (pt) [s, pt] = pt.split('S');

		return `${n(h)}:${n(m)}:${n(s)}`;
	};

	const handleRemoveVideo = async (videoId) => {
		const ls = JSON.parse(localStorage.getItem('gbuser') ?? {});
		await axios.delete(`${BASE_URL}/videos/${videoId}`, {
			headers: {
				Authorization: `Bearer ${ls.token}`,
			},
		});
		dispatch({ type: 'REMOVE_VIDEO', data: { videoID: videoId } });
	};

	return (
		<Col>
			<Space align="baseline" size="large">
				<div className="card-wrapper">
					{state.isLoggedIn && (
						<span className="card-delete-button">
							<Button onClick={() => handleRemoveVideo(`${video.videoId}`)}>
								<CloseCircleOutlined />
							</Button>
						</span>
					)}

					<Card
						className="card card-extra-no-padding"
						hoverable
						//onClick={() => navigate(`/watch/${video.videoId}`)}
						onClick={() => window.open(`https://www.youtube.com/embed/${video.videoId}`, '_blank')}
						cover={
							<img
								style={{ objectFit: 'cover' }}
								alt={video.originalTitle}
								src={video.thumbnails.medium.url}
								width={'320px'}
								height={'180px'}
							/>
						}
					>
						<Meta
							className="meta"
							title={video.originalTitle}
							description={convertPT(video.duration) + ' - ' + video.defaultAudioLanguage ?? 'en'}
						/>
					</Card>
				</div>
			</Space>
		</Col>
	);
};

export default VideoCard;
