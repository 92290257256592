import { useContext, createContext } from 'react';
import { useReducer } from 'react';

import { reducer } from './reducer';
import { initialState } from './initialState';

const AppContext = createContext();

const Provider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export const useAppState = () => {
	return useContext(AppContext);
};

export default Provider;
