import { Typography, Col, Row, Layout, Space, Button } from 'antd';
import { useEffect } from 'react';
import { useAppState } from '../../context';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const Home = () => {
	const { state, dispatch } = useAppState();
	const isLoggedIn = state.isLoggedIn;

	const navigate = useNavigate();

	useEffect(() => {
		const ls = localStorage.getItem('gbuser');
		const { token } = ls ? JSON.parse(ls) : '';
		if (token && !state.isLoggedIn) {
			dispatch({ type: 'SET_ISLOGIN', data: { isLoggedIn: true } });
		}
	}, []);

	return (
		<>
			{isLoggedIn && (
				<Space.Compact style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
					<Button onClick={() => navigate('/movielist')}>Movie List</Button>
					<Button onClick={() => navigate('/browse')}>Browse</Button>
				</Space.Compact>
			)}
			<Layout className="main">
				<Row>
					<Col flex={1} align={'middle'} justify={'center'}>
						<Title level={1} editable={false} className="genbays-title">
							<span style={{ color: '#F89F1D' }}> GEN</span>
							<span style={{ color: '#fdfdfd' }}>BAYS</span>
						</Title>
					</Col>
				</Row>
			</Layout>
		</>
	);
};

export default Home;
