import { Input, Modal } from 'antd';
import { useState } from 'react';

export default function AddMovieModal({ showModal, onCancelHandler, onOkHandler }) {
	const [ytLink, setYtLink] = useState('');
	const handleOnChangeText = (e) => {
		// TODO: Validate Youtube link
		setYtLink(e.target.value);
	};

	return (
		<Modal
			title="Add Movie"
			centered
			open={showModal}
			onOk={() => {
				onOkHandler(ytLink);
				setYtLink('');
			}}
			onCancel={onCancelHandler}
		>
			<Input placeholder=" youtube video Id" onChange={(e) => handleOnChangeText(e)} />
		</Modal>
	);
}
