import { Button, Space, Input } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BASE_URL } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../context';

export default function Login() {
	const { state, dispatch } = useAppState();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassowrd] = useState('');
	const isLoggedIn = state.isLoggedIn;

	useEffect(() => {
		const lc = JSON.parse(localStorage.getItem('gbuser'));
		if (isLoggedIn && lc.token.length) {
			navigate('/');
		}
	}, [isLoggedIn, navigate]);

	const handleSubmit = async () => {
		const token = await axios.post(`${BASE_URL}/login`, {
			email,
			password,
		});
		if (token.status === 200 && token.data) {
			localStorage.setItem('gbuser', JSON.stringify({ email, token: token.data }));
			dispatch({ type: 'SET_ISLOGIN', data: { isLoggedIn: true, userEmail: email } });
		}
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
			<Space direction="vertical">
				<Input placeholder="Username" onChange={(e) => setEmail(e.target.value)} />
				<Input.Password placeholder="Password" onChange={(e) => setPassowrd(e.target.value)} />
				<Button onClick={handleSubmit}>Submit</Button>
			</Space>
		</div>
	);
}
