import axios from 'axios';
import { useQuery } from 'react-query';
import ListVideos from '../../component/ListCards';
import { useAppState } from '../../context';
import { useEffect } from 'react';
import { Layout, Row, Space, Typography } from 'antd';
import MainHeader from '../../component/Header';
import { BASE_URL } from '../../utils/constants';

const { Content } = Layout;

function Browse() {
	const { state, dispatch } = useAppState();
	const { isLoading, error, data } = useQuery('get', async () => await axios.get(`${BASE_URL}/videos`), {
		enabled: !Object.keys(state.videos).length,
	});

	useEffect(() => {
		console.log('data', data);
		dispatch({ type: 'SET_VIDEOS', data });
	}, [data, dispatch]);

	return (
		<>
			<Layout>
				<Layout className="header-layout">
					<MainHeader />
				</Layout>

				<Layout>
					<Content>
						<Typography.Title level={4} style={{ color: 'white' }}>
							Recently Added
						</Typography.Title>
					</Content>

					<Row justify={'center'}>{!isLoading && !error && <ListVideos data={state.videos} />}</Row>
				</Layout>
			</Layout>
		</>
	);
}

export default Browse;
