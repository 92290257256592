import axios from 'axios';
import { useQuery } from 'react-query';
import { Layout, Table, Form } from 'antd';
import MainHeader from '../../component/Header';
import { BASE_URL } from '../../utils/constants';
import { useEffect, useState } from 'react';

import { columns } from './movielist-helper';
import { useAppState } from '../../context';
import { useNavigate } from 'react-router-dom';
import EditableCell from './EditableCell';

const { Content } = Layout;

const MovieList = (props) => {
	const { state, dispatch } = useAppState();
	const [form] = Form.useForm();

	const [response, setResponse] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [editingKey, setEditingKey] = useState('');

	const { isLoading, error, data } = useQuery('get', async () => await axios.get(`${BASE_URL}/videos`), {
		enabled: !Object.keys(state.videos).length,
	});
	const navigate = useNavigate();

	useEffect(() => {
		if (!state.isLoggedIn) {
			navigate('/');
		}
		setResponse(Object.values(state.videos));
	}, []);

	useEffect(() => {
		setResponse(Object.values(data?.data ?? {}));
	}, [data]);

	const onSelectChange = (newSelectedRowKeys) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const isEditing = (record) => record.videoId === editingKey;

	const edit = (record) => {
		form.setFieldsValue({
			...record,
		});
		setEditingKey(record.videoId);
	};

	const save = async (videoId) => {
		try {
			const row = await form.validateFields();
			const newData = [...response];
			const index = newData.findIndex((item) => videoId === item.videoId);
			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
				setResponse(newData);
				setEditingKey('');
			} else {
				newData.push(row);
				setResponse(newData);
				setEditingKey('');
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const cancel = () => {
		setEditingKey('');
	};

	// const deleteRecord = async (record) => {
	// 	const ls = JSON.parse(localStorage.getItem('gbuser') ?? {});
	// 	await axios.delete(`${BASE_URL}/videos/${record.videoId}`, {
	// 		headers: {
	// 			Authorization: `Bearer ${ls.token}`,
	// 		},
	// 	});
	// 	dispatch({ type: 'REMOVE_VIDEO', data: { videoID: record.videoId } });
	// 	const updatedResponse = response.filter((r) => r.videoId !== record.videoId);
	// 	setResponse(updatedResponse);
	// };
	const col = columns({ edit, editingKey, save, isEditing, cancel });

	const mergedColumns = col.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});
	return (
		<>
			<Layout>
				<Layout className="header-layout">
					<MainHeader />
				</Layout>

				<Layout>
					<Content style={{ marginTop: '5rem' }}>
						{!isLoading && !error && (
							<Form form={form} component={false}>
								<Table
									components={{
										body: {
											cell: EditableCell,
										},
									}}
									rowSelection={rowSelection}
									bordered={true}
									columns={mergedColumns}
									dataSource={response}
									rowClassName="editable-row"
									pagination={{
										onChange: cancel,
									}}
									rowKey="videoId"
									// onRow={(data, i) => {
									// 	return {
									// 		onClick: (event) => {
									// 			console.log(data);
									// 		},
									// 	};
									// }}
								/>
							</Form>
						)}
					</Content>
				</Layout>
			</Layout>
		</>
	);
};

export default MovieList;
