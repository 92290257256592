import { Navigate, createBrowserRouter } from 'react-router-dom';

import Browse from '../page/browse/Browse';
import Home from '../page/home';
import MovieList from '../page/movieList';
import Login from '../component/Login';

const router = createBrowserRouter([
	{ path: '/movielist', element: <MovieList /> },
	{
		path: '/browse',
		element: <Browse />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{ path: '/', element: <Home /> },
	{ path: '/*', element: <Navigate to="/" /> },
]);

export { router };
