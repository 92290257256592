const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_LANG':
			console.log(action);
			return {
				...state,
				settings: {
					...state.settings,
					userLanguage: 'ta',
				},
			};
		case 'SET_VIDEOS':
			const data = action?.data?.data ?? {};
			return {
				...state,
				videos: { ...data },
			};

		case 'REMOVE_VIDEO':
			const { videoID } = action.data;
			const videos = { ...state.videos };
			delete videos[videoID];

			return {
				...state,
				videos: { ...videos },
			};

		case 'SET_ISLOGIN':
			console.log(action);
			return {
				...state,
				isLoggedIn: action?.data?.isLoggedIn,
			};

		default:
			return state;
	}
};

export { reducer };
